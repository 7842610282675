import React from "react";

const TableViewIcon: React.FC = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4792 10.3534C18.7664 8.50958 17.5289 6.91501 15.9197 5.76681C14.3105 4.61861 12.4001 3.96711 10.4246 3.89282C8.44919 3.96711 6.53881 4.61861 4.92961 5.76681C3.3204 6.91501 2.08292 8.50958 1.37009 10.3534C1.32195 10.4866 1.32195 10.6324 1.37009 10.7655C2.08292 12.6094 3.3204 14.204 4.92961 15.3522C6.53881 16.5004 8.44919 17.1519 10.4246 17.2262C12.4001 17.1519 14.3105 16.5004 15.9197 15.3522C17.5289 14.204 18.7664 12.6094 19.4792 10.7655C19.5273 10.6324 19.5273 10.4866 19.4792 10.3534ZM10.4246 16.014C7.21251 16.014 3.81858 13.6322 2.58827 10.5595C3.81858 7.48676 7.21251 5.10494 10.4246 5.10494C13.6368 5.10494 17.0307 7.48676 18.261 10.5595C17.0307 13.6322 13.6368 16.014 10.4246 16.014Z"
      fill="#9B9B9B"
      stroke="#9B9B9B"
      strokeWidth="0.3"
    />
    <path
      d="M10.4254 6.9231C9.70622 6.9231 9.00317 7.13636 8.40517 7.53593C7.80718 7.9355 7.34109 8.50342 7.06587 9.16788C6.79064 9.83234 6.71863 10.5635 6.85894 11.2689C6.99925 11.9743 7.34558 12.6222 7.85413 13.1308C8.36268 13.6393 9.01062 13.9856 9.71601 14.126C10.4214 14.2663 11.1525 14.1942 11.817 13.919C12.4815 13.6438 13.0494 13.1777 13.449 12.5797C13.8485 11.9817 14.0618 11.2787 14.0618 10.5595C14.0618 9.59504 13.6787 8.67011 12.9967 7.98816C12.3148 7.30621 11.3899 6.9231 10.4254 6.9231ZM10.4254 12.9837C9.94596 12.9837 9.47726 12.8415 9.07859 12.5751C8.67993 12.3088 8.3692 11.9301 8.18572 11.4872C8.00223 11.0442 7.95423 10.5568 8.04777 10.0865C8.14131 9.61626 8.37219 9.1843 8.71123 8.84526C9.05027 8.50622 9.48222 8.27534 9.95248 8.1818C10.4227 8.08826 10.9102 8.13627 11.3531 8.31975C11.7961 8.50324 12.1747 8.81396 12.4411 9.21262C12.7075 9.61129 12.8497 10.08 12.8497 10.5595C12.8497 11.2024 12.5943 11.819 12.1396 12.2737C11.685 12.7283 11.0684 12.9837 10.4254 12.9837Z"
      fill="#9B9B9B"
      stroke="#9B9B9B"
      strokeWidth="0.3"
    />
  </svg>
);

export default TableViewIcon;
