export const REQ_STATUS = [
  "Reject",
  "New",
  "In Progress",
  "Legal Paperwork Sent",
  "Approval Pending",
  "Approved",
];

export const REQ_STATUS_COLOR = [
  "text-[#FF4F4B]",
  "text-[#ff4bc6]",
  "text-[#F4BC1C]",
  "text-[#0288D1]",
  "text-[#964bff]",
  "text-primary-main",
];

export const SUB_PLANS = ["Basic", "Pro", "Business", "Enterprise"];

export enum REQ_NOTE_TYPE {
  INTERNAL_NOTE = 1,
  CLIENT_COMMUNICATION_NOTE = 2,
}

export const CHARGER_ATTRIBUTES: Record<string, string> = {
  vehicle_type: "Vehicle Type",
  brand: "Brand",
  charger_model: "Charger Model",
  maximum_output_power: "Maximum Output power",
  maximum_output_current: "Maximum output current",
  input_voltage: "Input Voltage",
  authentication: "Authentication",
  charging_standard: "Charging standard",
  connector_soket_type: "Connector/Socket Type",
  communication_protocol: "Communication Protocol",
  protection: "Protection",
  charging_mode: "Charging Mode",
  number_of_output: "Number of output",
  network_connection: "Network connection",
};

export enum REQ_STATUS_ENUM {
  REJECT = 0,
  NEW = 1,
  IN_PROGRESS = 2,
  LEGAL_PAPERWORK_SENT = 3,
  APPROVAL_PENDING = 4,
  APPROVED = 5,
}

export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_MANAGER = "SM",
  SUPER_EMPLOYEE = "SE",

  FLEET_ADMIN = "FA",
  FLEET_MANAGER = "FM",
  FLEET_EMPLOYEE = "FE",

  STATION_ADMIN = "STA",
  STATION_MANAGER = "STM",
  STATION_EMPLOYEE = "STE",
  STATION_INSTALLER = "STI",

  ORGANIZATION_ADMIN = "OA",
  ORGANIZATION_MANAGER = "OM",
  ORGANIZATION_EMPLOYEE = "OE",

  DRIVER = "DR",
}
